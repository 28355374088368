<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    import moment from 'moment';

    export default {
        props: ['filter', 'totalCount', 'params','prime'],
        mixins: [Export],
        data() {
            let headers = [
                {header: 'code', key: 'code', label: 'Mã NKT', width: 20},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên', width: 20},
                    {header: 'dob', key: 'dob', label: 'Năm sinh', width: 15},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 26},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 15},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 15},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 13},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính', width: 13},
                    {header: 'types', key: 'types', label: 'Dạng tật (6 nhóm, có để đa khuyết tật)', width: 30},
                    {header: 'agent_orange_victim', key: 'agent_orange_victim', label: 'Nạn nhân da cam', width: 26},
                    {header: 'degree_disability', key: 'degree_disability', label: 'Mức độ khuyết tật', width: 20},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày đánh giá gần nhất', width: 20},
                    {header: 'result', key: 'result', label: 'Kết quả đánh giá', width: 20},
                    {header: 'improvements', key: 'improvements', label: 'Lĩnh vực có cải thiện', width: 40},
            ];
            if (this.prime) {
                headers.unshift({header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 20});
            }
            return {
                service: '/patient-life',
                fileName: `chat_luong_cuoc_song.xlsx`,
                headers,
                
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);


                let provinces = [];
                var tb = this.params.provinceImport;
                for (const key in tb) {
                    provinces.push(tb[key]);
                }


                row = {};
                if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row['code'] = "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)";
                row['fullname'] = "Nhập định dạng chữ (tối đa 200 ký tự)";
                row["dob"] = "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)";
                row["province_id"] = "Nhập định dạng chữ (" + provinces.toString() + ")";
                row["district_id"] = "Nhập định dạng chữ";
                row["ward_id"] = "Nhập định dạng chữ";
                row['race_id'] = "Nhập định dạng chữ";
                row['gender_id'] = "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)";
                row['types'] = "Nhập 01 hoặc nhiều giá trị số từ 1 đến 6 (Trong đó: " + this.$params.patient.typeOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row['agent_orange_victim'] = "Nhập 01 giá trị số từ 1 đến 3 (Trong đó:" + this.$params.patient.victimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row['degree_disability'] = "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: " + this.$params.patient.degreeDisabilityOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";
                row["rate_time"] = "Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu";
                row['result'] = "Nhập 01 giá trị số từ 1 đến 2 (1. Có cải thiện/2. Không cải thiện)";
                row['improvements'] = "Nhập 01 hoặc nhiều giá trị số từ 1 đến 9 (Trong đó: " + this.$params.patientLife.improvementOptions.filter(item => item.value != null).map(item => item.text).toString() + ")";


                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };


            },
            addRow(item, params) {
                let row = {
                    code: item.code,
                    fullname: item.fullname,
                    dob: item.dob != 0 ? moment(item.dob * 1000).format('YYYY') : '',
                    rate_time: item.rate_time > 0 ? moment(item.rate_time * 1000).format('DD/MM/YYYY') : '',
                    province_id: params.provinces && params.provinces[item.province_id] ? params.provinces[item.province_id].name : '',
                    district_id: params.districts && params.districts[item.district_id] ? params.districts[item.district_id] : '',
                    ward_id: params.wards && params.wards[item.ward_id] ? params.wards[item.ward_id] : '',
                    race_id: params.race[item.race_id] ? params.race[item.race_id] : "",
                    gender_id : item.gender_id != null ? this.$params.gender.find(o => o.value == parseInt(item.gender_id)).text : '',
                    start_time: item.start_time > 0 ? moment(item.start_time).format('DD/MM/YYYY') : '',
                    end_time: item.end_time > 0 ? moment(item.end_time).format('DD/MM/YYYY') : '',
                    result: item.result == 1 ? "1. Có cải thiện" : item.result == 2 ? "2. Không cải thiện" : '',
                    types: this.getTypes(params.tys[item.id] ? params.tys[item.id] : null),
                    improvements: this.getImps(params.improvements[item.id] ? params.improvements[item.id] : null),
                    agent_orange_victim: item.agent_orange_victim && this.$params.patient.victimOptions.find(o => o.value == item.agent_orange_victim) ? this.$params.patient.victimOptions.find(o => o.value == item.agent_orange_victim).text : null,
                    degree_disability: item.degree_disability && this.$params.patient.degreeDisabilityOptions.find(o => o.value == item.degree_disability) ? this.$params.patient.degreeDisabilityOptions.find(o => o.value == item.degree_disability).text : null,
                };
                if(this.prime){
                    row['client_id'] = params.clients[item.client_id].short_name;
                }
                return row;
            },
            getTypes(type) {
                let text = "";
                if (type != null) {
                    for (let item of type) {
                        if (this.$params.patient.typeOptions.find(o => o.value == item)) {
                            text = text + this.$params.patient.typeOptions.find(o => o.value == item).text + "; ";
                        }
                    }
                }
                return text;
            },
            getImps(imp){
                let text = "";
                if (imp != null) {
                    for (let item of imp) {
                        if (this.$params.patientLife.improvementOptions.find(o => o.value == item)) {
                            text = text + this.$params.patientLife.improvementOptions.find(o => o.value == item).text + "; ";
                        }
                    }
                }
                return text;
            }
        },
    }
</script>