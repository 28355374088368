<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập mã, họ tên ...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Kết quả đo lường chất lượng cuộc sống" titleIcon="fa fa-bible">
                <template slot="filter">
                    <client-select v-if='isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='search' />
                    <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='search' />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="types"  :options="$params.patient.typeOptions" :isFilter="true" @change="search" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="degree_disability"  :options="$params.patient.degreeDisabilityOptions" :isFilter="true" @change="search" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="agent_orange_victim"  :options="$params.patient.victimOptions" :isFilter="true" @change="search" />
                    <dropdown   :right-menu="true" type="bordered" icon="" class="mr-2" :model="filterFormModel" attribute="result"  :options="$params.patientLife.resultOptions" :isFilter="true" @change="search" />
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(info)="{item}">
                    <div v-if="item.dob"><span class="small text-gray-999">Năm sinh: </span>{{item.dob * 1000 |  moment('YYYY')}}</div>
                    <div v-if="item.gender_id != null"><span class="small text-gray-999">Giới tính: </span>{{$params.gender.find(o => o.value === item.gender_id).text}}</div>
                    <div v-if="item.race_id" ><span class="small text-gray-999">Dân tộc: </span> 
                        <span v-if='params.race && params.race[item.race_id]'>{{params.race[item.race_id]}}</span>
                    </div>
                    <div ><span v-if='params.provinces[item.province_id]' class="small text-gray-999">Nơi sinh sống: </span>
                        <span v-if='params.wards[item.ward_id]' >{{params.wards[item.ward_id]}}, </span>
                        <span v-if='params.districts[item.district_id]' >{{params.districts[item.district_id]}}, </span>
                        <span v-if='params.provinces[item.province_id]' >{{params.provinces[item.province_id].name}}</span>
                    </div>
                </template>
                <template #cell(code)="{item}">
                    <div v-if="item.code"><span class="small text-gray-999">Mã: </span>{{item.code}}</div>
                    <div v-if="item.fullname"><span class="small text-gray-999">Họ và tên: </span>{{item.fullname}}</div>
                </template>
                <template #cell(types)="{item}">
                    <div v-if="params.tys[item.id]" ><span class="small text-gray-999">Dạng khuyết tật: </span> 
                        <span v-for="tys_id in params.tys[item.id]" :key="tys_id" >
                            <span class="badge bg-gray ml-1" v-if='$params.patient.typeOptions.find(o => o.value === tys_id)'>{{ $params.patient.typeOptions.find(o => o.value === tys_id).text}}</span>
                        </span>
                    </div>
                    <div v-if="item.agent_orange_victim" ><span class="small text-gray-999">Nạn nhân da cam: </span> 
                        <span   v-if='$params.patient.victimOptions.find(o => o.value === item.agent_orange_victim)'>{{ $params.patient.victimOptions.find(o => o.value === item.agent_orange_victim).text}}</span>
                    </div>
                    <div v-if="item.degree_disability" ><span class="small text-gray-999">Mức độ khuyết tật: </span> 
                        <span   v-if='$params.patient.degreeDisabilityOptions.find(o => o.value === item.degree_disability)'>{{ $params.patient.degreeDisabilityOptions.find(o => o.value === item.degree_disability).text}}</span>
                    </div>
                </template>
                <template #cell(tool)="{item}">
                    <div v-if="item.rate_time"><span class="small text-gray-999">Ngày đánh giá gần nhất: </span>{{item.rate_time * 1000 |  moment('DD/MM/YYYY')}}</div>
                    <div v-if="item.result" ><span class="small text-gray-999">Kết quả đánh giá: </span> 
                        <span   v-if='$params.patientLife.resultOptions.find(o => o.value === item.result)'>{{ $params.patientLife.resultOptions.find(o => o.value === item.result).text}}</span>
                    </div>
                    <div v-if="params.improvements[item.id]" ><span class="small text-gray-999">Lĩnh vực cải thiện: </span> 
                        <span v-for="imp_id in params.improvements[item.id]" :key="imp_id" >
                            <span class="badge bg-gray ml-1" v-if='$params.patientLife.improvementOptions.find(o => o.value === imp_id)'>{{ $params.patientLife.improvementOptions.find(o => o.value === imp_id).text}}</span>
                        </span>
                    </div>
                </template>

                <template slot="action">
                    <export :filter="filterFormModel" :params='params' :prime="isPrime() || isTW() || isUsaid()" :totalCount="itemCount" class="mr-1"/>
                    <g-button class="ml-1 btn btn-gray btn-sm" icon="fa fa-file-excel" v-if="isPrime() && this.$store.state.client.type !== 'tw'" :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                    <import :client_id="params.clientId" v-if="isPrime() && this.$store.state.client.type !== 'tw'" :params='params' class="mr-1"/>
                    <g-button class="ml-2" :loading="saveLoading" size="sm" variant="success" v-if="isPrime() && this.$store.state.client.type !== 'tw'" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(action)="{item}">
                    <g-button v-if="permissions('patient-life_view')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('patient-life_update') && isPrime()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"  @click="update(item.id)" />
                    <g-button  v-if="permissions('patient-life_del') && isPrime()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <log type="patient-life" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='xl' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-4'>
                    <form-input label="Mã NKT" :required='true' :model="formModel" attribute="code" @change="changePatient('code')"  :errors="formErrors" ></form-input>
                </div>
                <div class='col-md-4'>
                    <form-input label="Họ và tên" :disabled="false" :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-date-time label="Năm sinh" :disabled="false" :required='true' :model="formModel" :errors="formErrors" attribute="dob" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md'> 
                    <form-select label="Giới tính" :disabled="false" :required='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md'>
                    <parameter-select label="Dân tộc" :disabled="false" :type="$params.const.race.key"  :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true' :provinceDisabled='false' :districtDisabled='false' :wardDisabled="false"></form-group-address>
            <div class='row'>
                <div class='col-md'>
                    <form-select-multiple :close-on-select='false' :disabled="false" :required='true' label="Dạng khuyết tật" :model="formModel" attribute="types" :errors="formErrors" :options="patientTypeOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Mức độ khuyết tật" :disabled="false" :required='true' :model="formModel" attribute="degree_disability" :errors="formErrors" :options="$params.patient.degreeDisabilityOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Nạn nhân da cam" :disabled="false" :required='true' :model="formModel" attribute="agent_orange_victim" :errors="formErrors" :options="$params.patient.victimOptions" />
                </div>
            </div>
            <div class='row'>
                 <div class='col-md-4'>
                    <form-date-time  :required='true'  label="Ngày đánh giá gần nhất" :model="formModel" :errors="formErrors" attribute="rate_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-select  :required='true'  label="Kết quả đánh giá" :model="formModel" attribute="result" :errors="formErrors" :options="$params.patientLife.resultOptions" />
                </div>
                <div class='col-md-4'>
                    <form-select-multiple :close-on-select='false' :disabled="false" label="Lĩnh vực cải thiện" :model="formModel" attribute="improvements" :errors="formErrors" :options="patientTypeImprovement" />
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='xl' :title="formTitle" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-md-4'>
                    <form-input label="Mã NKT" :disabled='true' :required='true' :model="formModel" attribute="code" :errors="formErrors" ></form-input>
                </div>
                <div class='col-md-4'>
                    <form-input label="Họ và tên" :disabled='true' :required='true' :model="formModel" attribute="fullname"  :errors="formErrors" ></form-input>
                </div>
            </div> 
            <div class='row'>
                <div class='col-md'>
                    <form-date-time label="Năm sinh" :required='true' :disabled='true' :model="formModel" :errors="formErrors" attribute="dob" format="YYYY" placeholder="YYYY" />
                </div>
                <div class='col-md'>
                    <form-select label="Giới tính" :required='true' :disabled='true' :model="formModel" attribute="gender_id" :errors="formErrors" :options="$params.gender" />
                </div>
                <div class='col-md'>
                    <parameter-select label="Dân tộc" :disabled='true' :type="$params.const.race.key"  :model="formModel" attribute="race_id" />
                </div>
            </div> 
            <form-group-address class="pr-1" :model="formModel" :errors="formErrors"  :districtDisabled="true" :wardDisabled="true" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
            <div class='row'>
                <div class='col-md'>
                    <form-select-multiple :disabled='true' :close-on-select='false' label="Dạng khuyết tật" :required='true' :model="formModel" attribute="types" :errors="formErrors" :options="patientTypeOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Mức độ khuyết tật" :required='true' :disabled='true' :model="formModel" attribute="degree_disability" :errors="formErrors" :options="$params.patient.degreeDisabilityOptions" />
                </div>
                <div class='col-md'>
                    <form-select label="Nạn nhân da cam" :required='true' :disabled='true' :model="formModel" attribute="agent_orange_victim" :errors="formErrors" :options="$params.patient.victimOptions" />
                </div>
            </div>
            <div class='row'>
                 <div class='col-md-4'>
                    <form-date-time  :required='true' :disabled='true'  label="Ngày đánh giá gần nhất" :model="formModel" :errors="formErrors" attribute="rate_time" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" />
                </div>
                <div class='col-md-4'>
                    <form-select  :required='true' :disabled='true'  label="Kết quả đánh giá" :model="formModel" attribute="result" :errors="formErrors" :options="$params.patientLife.resultOptions" />
                </div>
                <div class='col-md-4'>
                    <form-select-multiple :disabled='true' :close-on-select='false' label="Lĩnh vực cải thiện" :model="formModel" attribute="improvements" :errors="formErrors" :options="patientTypeImprovement" />
                </div>
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    import ProvinceSelect from '@/components/ProvinceSelect';
    import ParameterSelect from '@/components/ParameterSelect';
    import Log from "@/components/Log";
    import Import from './PatientLifeImport';
    import Export from './PatientLifeExport';
    import ImportMinxi from "@/mixins/Template";
    import ClientSelect from '@/components/ClientSelect';

    export default {
        components: {ProvinceSelect, ParameterSelect, Log, Dropdown, Import,Export, ClientSelect},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                reloadBus: "reloadPatientLife",
                service: '/patient-life',
                defaultFilterFormModel: {
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    keyword: '',
                    types: null,
                    degree_disability: null,
                    agent_orange_victim: null,
                    result: null,
                    improvements : null,
                    client_id: this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                },
                defaultFormModel: {
                    types: [],
                    improvements: [],
                    agent_orange_victim: null,
                    degree_disability: null,
                    code: null,
                    race_id: null,
                    result: null,
                    gender_id: null,
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                    dob: null,
                },
                patientTypeOptions: [],
                patientTypeImprovement: [],
                showFormView: false,
                //template mẫu 
                fileName: `chat_luong_cuoc_song`,
                templateName: 'chat_luong_cuoc_song.xlsx',
                headers: [
                    {header: 'code', key: 'code',label: 'Mã NKT*', width: 30, desc: "Nhập định dạng chữ (BHYT/CCCD/CMT/Mã hồ sơ NKT - tối đa 100 ký tự)"},
                    {header: 'fullname', key: 'fullname', label: 'Họ và tên*', width: 30, desc: "Nhập định dạng chữ (tối đa 200 ký tự)"},
                    {header: 'dob', key: 'dob', label: 'Năm sinh*', width: 15, desc: "Nhập định dạng số (yyyy - Giá trị từ 1911 đến năm nhập dữ liệu)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 25, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 25, desc: "Nhập định dạng chữ"},
                    {header: 'race_id', key: 'race_id', label: 'Dân tộc', width: 15, desc: "Nhập định dạng chữ"},
                    {header: 'gender_id', key: 'gender_id', label: 'Giới tính*', width: 15, desc: "Nhập 01 giá trị dạng chữ (Nam/Nữ/Khác)"},
                    {header: 'types', key: 'types', label: 'Dạng tật (6 nhóm, có để đa khuyết tật)*', width: 25, desc: "Nhập 01 hoặc nhiều giá trị số từ 1 đến 6 (Trong đó: 1. Khuyết tật vận động/2. Khuyết tật nghe, nói/3. Khuyết tật nhìn/4. Khuyết tật thần kinh, tâm thần/5. Khuyết tật trí tuệ/6. Khuyết tật khác)."},
                    {header: 'agent_orange_victim', key: 'agent_orange_victim', label: 'Nạn nhân da cam*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 3 (Trong đó: " + this.$params.patient.victimOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'degree_disability', key: 'degree_disability', label: 'Mức độ khuyết tật*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: 1.Nhẹ/2.Nặng/3.Đặc biệt nặng/4. Không xác định)"},
                    {header: 'rate_time', key: 'rate_time', label: 'Ngày đánh giá gần nhất*', width: 25, desc: 'Nhập định dạng dd/mm/yyyy. Thời gian phải trước hoặc bằng ngày nhập dữ liệu'},
                    {header: 'result', key: 'result', label: 'Kết quả đánh giá *', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 2 (Trong đó: 1. Có cải thiện/2. Không cải thiện)"},
                    {header: 'improvements', key: 'improvements', label: 'Lĩnh vực có cải thiện', width: 40, desc: "Nhập 01 hoặc nhiều giá trị số từ 1 đến 9 (" + this.$params.patientLife.improvementOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ],
                indexRow: 4,
            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'code', label: "Khách hàng"},
                    {key: 'info', label: "Thông tin khách hàng"},
                    {key: 'types', label: "Thông tin khác"},
                    {key: 'tool', label: "Thông tin đánh giá"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
            "formModel.code": {
                handler(val) {
                    if (val) {
                        this.formModel.code =  val.toUpperCase().trim();
                    }
                }, deep: true
            }
        },
        methods: {
            beforeUpdate(data) {
                data.dob = data.dob ? data.dob * 1000 : null;
                data.rate_time = data.rate_time ? data.rate_time * 1000 : null;
            },
            getPatientTypeOption(){
                for (let i in this.$params.patient.typeOptions){
                    let id = this.$params.patient.typeOptions[i];
                    if(id.value != null){
                        this.patientTypeOptions.push(id);
                    }  
                }
                return this.patientTypeOptions;
            },
            getPatientTypeImprovement(){
                for (let i in this.$params.patientLife.improvementOptions){
                    let id = this.$params.patientLife.improvementOptions[i];
                    if(id.value != null){
                        this.patientTypeImprovement.push(id);
                    }  
                }
                return this.patientTypeImprovement;
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin khách hàng: " + this.formModel.fullname;
                    this.formModel.id = id;
                    this.formModel.dob = response.data.dob ? response.data.dob * 1000 : null;
                    this.formModel.rate_time = response.data.rate_time ? response.data.rate_time * 1000 : null;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
            changePatient(attribute) {
                this.formModel.fullname = null;
                this.formModel.dob = null;
                this.formModel.gender_id = null;
                this.formModel.race_id = null;
                this.formModel.types = null;
                this.formModel.ward_id = null;
                this.formModel.province_id = null;
                this.formModel.district_id = null;
                this.formModel.degree_disability = null;
                this.formModel.agent_orange_victim = null;
                this.formModel.types = null;
                this.formModel.improvements = null;
                if (attribute == 'code') {
                    if(this.formModel.code){
                        this.formModel.types = [];
                        this.formModel.improvements = [];
                        this.$service.get(`patient-life/get-by-code2?code=${this.formModel.code}`).then(response => {
                            this.formModel.code = response.data.code;
                            this.formModel.fullname = response.data.fullname;
                            this.formModel.dob = response.data.dob ? response.data.dob * 1000 : null;
                            this.formModel.gender_id = response.data.gender_id;
                            this.formModel.race_id = response.data.race_id;
                            this.formModel.types = response.data.types;
                            this.formModel.improvements = response.data.improvements
                            this.formModel.province_id = response.data.province_id;
                            this.formModel.district_id = response.data.district_id;
                            this.formModel.ward_id = response.data.ward_id;
                            this.formModel.degree_disability = response.data.degree_disability;
                            this.formModel.agent_orange_victim = response.data.agent_orange_victim;
                        })
                    } else {
                        this.formModel = this.defaultFormModel;
                    }
                }
            },
        },
        mounted(){
            this.getPatientTypeOption();
            this.getPatientTypeImprovement();
        },
    }
</script>